import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useNavigate, useParams }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as TankerTrucksService } from 'services/TankerTrucks';
import { toast } from 'react-toastify';

const BackButton = styled.button`
	width: 30px;
	height: 30px;
	position: absolute;
	top: 10px;
	z-index: 2;
	left: 50%;
	margin-left: -15px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 4px solid var(--plenoil-orange);
	color: black;
	background: lightgray;
	border-radius: 50%;
	font-size: 14px;

	i {

		&::before {
			-webkit-text-stroke: 1px;
		}
	}
`;

const Wrapper = styled.div`
	position: absolute;
	top: 25px;
	width: 100%;
	height: 100%;
	background: var(--plenoil-orange);
	color: white;
	overflow-y: auto;

	.page-title {
		margin-top: 30px;
		text-align: center;
		font-size: 18px;
	}

	.white-square {
		width: 90%;
		margin: 0 auto;
		background: white;
		border-radius: 30px;
		padding: 20px;
		padding-bottom: 30px;

		.field-label {
			color: var(--plenoil-black);
			text-align: center;
			font-size: 20px;
			font-weight: bold;
			margin-bottom: 5px;
			position: relative;

			.bar {
				position: absolute;
				left: 0px;
				top: 33%;
				width: 90px;
				height: 10px;
				display: inline-block;
				background: red;

				&.goa {
					background: var(--plenoil-black);
				}

				&.sp95 {
					background: var(--plenoil-green);
				}
			}
		}

		input[type="text"],
		input[type="number"] {
			font-size: 32px;
			text-align: center;
			border-radius: 15px;
			border: 1px solid var(--plenoil-black);
			padding: 0 10px;
		}

		#send-btn {
			margin: 0 auto;
			margin-top: 30px;
			display: block;
			
			background: var(--plenoil-orange);
			color: white;
			border: 0;
			border-radius: 45px;
			width: 150px;
			font-size: 20px;
			outline: 0 !important;
			position: relative;
			text-transform: uppercase;

			i {
				background: var(--plenoil-blue);
				border-radius: 50%;
				font-size: 25px;
				width: 25px;
				height: 25px;
				margin: 2.5px;
				color: yellow;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				left: 2px;
				top: 1px;
			}

			span {
				display: inline-block;
				font-size: 16px;
				margin-left: 20px;
			}	

			&:disabled {
				opacity: 0.5;
			}
		}

		#photos-btn {
			margin-top: 80px;
			text-align: center;

			a {
				color: var(--plenoil-black);
				text-decoration: none;
				display: inline-flex;
			}

			button {
				margin-bottom: 15px;
				background: var(--plenoil-blue);
				color: var(--plenoil-yellow);
				border: 0;
				border-radius: 50%;
				width: 65px;
				height: 65px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				outline: 0 !important;
				margin-right: 15px;

				i {
					margin-top: 2.5px;
					font-size: 40px;
				}
			}

			div {
				font-size: 25px;
				line-height: 30px;
				text-align: left;
				font-weight: 600;

				span {
					font-size: 18px;
					font-weight: normal;
				}
			}
		}
	}
`;

export default function TankerTrucksCheckManualData() {
	let navigate = useNavigate();
	let params = useParams();

	let id = params.id;

	let [tankerTruck, setTankerTruck] = useState(null);

	let [albaran, setAlbaran] = useState(null);
	let [goaLiters, setGoaLiters] = useState(null);
	let [sp95Liters, setSp95Liters] = useState(null);

	let [dataSentOk, setDataSentOk] = useState(false);

	useEffect(() => {		
		if ( !id ) return;

		const getData = async () => {
			let data = await TankerTrucksService.getCurrentById(id);
			if ( !data?.id || data?.status === 5 ) {
				navigate('/');
				return;
			}
			
			setTankerTruck(data);

			setAlbaran(data?.manualdata?.albaran ?? null);
			setGoaLiters(data?.manualdata?.goa_liters ?? null);
			setSp95Liters(data?.manualdata?.sp95_liters ?? null);
			setDataSentOk(false);
		};
		getData();

		return function cleanup() {
			TankerTrucksService.cancel();
		}
	}, [navigate, id]);

	// creates a function that uses TankerTrucksService to send the data
	const sendData = async () => {
		let data = {
			albaran: albaran,
			goa_liters: goaLiters,
			sp95_liters: sp95Liters,
		};

		let result = await TankerTrucksService.updateManualData(tankerTruck?.id, data);
		if ( result ) {
			toast.success('Datos enviados correctamente');
			setDataSentOk(true)
		} else {
			toast.error('Ha ocurrido un error al enviar los datos');
		}
	}

	return (
		<LoggedLayout hideFooter={true}>
			<BackButton id="back-button" onClick={() => navigate('/tankertrucks/check/' + tankerTruck?.id)}>
				<i className="bi bi-chevron-left"></i>
			</BackButton>

			<Wrapper>
				<div className="page-title">
					{tankerTruck?.order ?? ''}
				</div>
				
				<div className="white-square">
					<div className="field-label">
						Nº ALBARAN
					</div>
					<input type="text" className="form-control" value={albaran ?? ''} onChange={(e) => setAlbaran(e.target.value)} disabled={dataSentOk} />

					{ tankerTruck?.diesel_liters && 
						<>
							<div className="field-label mt-3">
								<span className="bar goa"></span> LITROS GOA
							</div>
							<input type="number" className="form-control" value={goaLiters ?? ''} onChange={(e) => setGoaLiters(e.target.value)} disabled={dataSentOk} />
						</>
					}
					
					{ tankerTruck?.sp95_liters &&
						<>
							<div className="field-label mt-3">
								<span className="bar sp95"></span> LITROS SP95
							</div>
							<input type="number" className="form-control" value={sp95Liters ?? ''} onChange={(e) => setSp95Liters(e.target.value)} disabled={dataSentOk} />
						</>
					}

					<button 
						id="send-btn" 
						onClick={sendData} 
						disabled={(() => {
							if ( albaran === null ) return true;
							if ( tankerTruck?.diesel_liters && !goaLiters ) return true;
							if ( tankerTruck?.sp95_liters && !sp95Liters ) return true;
							if ( dataSentOk ) return true;
							return false; 
						})()}>
						<i className="bi bi-check"></i>
						<span>Enviar</span>
					</button>
					
					{ (dataSentOk || tankerTruck?.manualdata) &&
						<div id="photos-btn">
							<NavLink to={'/tankertrucks/check/photos/' + tankerTruck?.id}>
								<button><i className="bi bi-camera"></i></button>
								<div>
									AÑADIR FOTOS<br />
									<span>ALBARANES</span>
								</div>
							</NavLink>
						</div>
					}
				</div>
			</Wrapper>
		</LoggedLayout>
	);
}


