import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { assets } from 'helpers/generic';
import { hasCapability } from 'helpers/user';
import { default as SuppliesService } from 'services/Supplies';
import moment from 'moment';

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;

	#pending {
		color: black;
		font-size: 14px;
		margin-top: -10px;
	}
`;

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-orange);
	border-radius: 50%;
	font-size: 20px;
	margin-bottom: 20px;
	align-self: start;
	margin-left: 10px;
`;

const SelectButton = styled.button` 
	border: 0;
	background: transparent;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	font-weight: bold;

	img {
		width: 80px;
		height: 80px;
		margin: 10px;
		margin-bottom: 10px;
	}

	span {
		display: block;
	}

	margin-bottom: 10px;
`;

const HistorialBtn = styled.button` 
	border: 0;
	background: transparent;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	span {
		display: block;
		font-size: 12px;
	}

	i {
		color: gray;
		background: lightgray;
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	margin-top: auto;
	margin-bottom: 40px;
`;

const PendingOrderToCheck = styled.div`
	background: var(--plenoil-orange);
	width: 80%;
	display: flex;
	flex-direction: row;
	padding: 20px;
	border-radius: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;

	.left {
		display: flex;
		align-items: center;
		padding-right: 20px;
		
		i {
			display: flex;
			justify-content: center;
			align-items: center;
			background: white;
			color: var(--plenoil-orange);
			border-radius: 50%;
			width: 35px;
			height: 35px;
		}
	}

	button {
		position: absolute;
		bottom: -15px;
		left: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--plenoil-blue);
		color: white;
		border-radius: 20px;
		font-size: 15px;
		border: 0;
		height: 30px;
		width: 200px;
		margin-left: -100px;
		outline: none;
	}
`;

export default function Supplies() {
	let navigate = useNavigate();

	let [pending, setPending] = useState(null);
	let [pendingToCreate, setPendingToCreate] = useState(null);

	useEffect(() => {
		const getPendingToCheck = async () => {
			let pendings = await SuppliesService.getBCOrdersToCheckPending();
			let pending = pendings ? pendings[0] : null;
			setPending(pending);
		}
		getPendingToCheck();

		const getPendingToCreate = async () => {
			let data = await SuppliesService.getPendingPlanning();
			setPendingToCreate(data);
		}
		getPendingToCreate();
	}, []);

	return (
		<LoggedLayout hideFooter={true}>
			<Wrapper>
				<CloseButton onClick={() => navigate('/')}>
					<i className="bi bi-chevron-left"></i>
				</CloseButton>

				{ hasCapability('app_supplies', 'add') &&
					<>
						<SelectButton onClick={() => navigate('/supplies/new-order')}>
							<img src={assets('/img/suministros.svg')} alt="nuevo pedido" />
							<span>NUEVO INVENTARIO</span>
						</SelectButton>
						{ pendingToCreate &&
							<span id="pending">PREVISTO: {moment(pendingToCreate.next_planning).format('DD/MM/YYYY')}</span>
						}
					</>
					
				}

				{ (hasCapability('app_supplies', 'add') && pending) &&
					<PendingOrderToCheck>
						<div className="left">
							<i className="bi bi-grid"></i>
						</div>
						<div className="right">
							<div>PEDIDO: {pending.code}</div>
							<div>ESTADO: APROBADO</div>
						</div>
						<button onClick={() => navigate('/supplies/bc-ordertc/' + pending.id)}>COMPROBAR PEDIDO</button>
					</PendingOrderToCheck>
				}

				{ hasCapability('app_supplies') &&
					<HistorialBtn onClick={() => navigate('/supplies/orders-list')}>
						<i className="bi bi-list"></i>
						<span>HISTORIAL DE INVENTARIOS</span>
					</HistorialBtn>
				}
			</Wrapper>
		</LoggedLayout>
	);
}


