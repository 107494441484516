import axios from 'axios';

export default class TankerTrucks {

	static axiosCancelToken = null;

	static getCurrentById = (id = null, params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/tankertrucks/get-current-by-id/' + id, {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static getCurrentsList = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/tankertrucks/get-currents-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		})
	}

	static getNextsList = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/tankertrucks/get-nexts-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		})
	}
	
	static updateField = (id, field, value) => {
		this.newAxiosCancelToken();

		return axios.post('api/tankertrucks/update-field/' + id, {
			field: field,
			value: value
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		})
	}

	static updateIncidence = (id, data) => {
		this.newAxiosCancelToken();

		return axios.post('api/tankertrucks/update-incidence/' + id, data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		})
	}

	static updateManualData = (id, data) => {
		this.newAxiosCancelToken();

		return axios.post('api/tankertrucks/update-manual-data/' + id, data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		})
	}

	static uploadPhotos = (id, photos) => {
		this.newAxiosCancelToken();

		return axios.post('api/tankertrucks/upload-photos/' + id, photos, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}