import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import Supplies from 'services/Supplies';
import { hasCapability } from 'helpers/user';

const StyledWrapper = styled.div`
	background: gray;
	width: 100%;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
        border-radius: 20px;
        border: 1px solid white;
        padding: 5px 30px;
    }

    span {
        font-size: 15px;
        color: white;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        text-transform: uppercase;
    }
`;

export default function SupplesPendingCheck() {
	let [pending, setPending] = useState(null);

	useEffect(() => {
        if ( !hasCapability('app_supplies') ) return;

		const getData = async () => {
			let data = await Supplies.getPendingPlanning();
			setPending(data);
		};

		let getPendingInterval = setInterval(() => {
			getData();
		}, 5000);
		getData();

		return function cleanup() {
			clearInterval(getPendingInterval);
			Supplies.cancel();
		}
	}, []);

	return (
        <React.Fragment>
            {pending &&
                <StyledWrapper>
                    <NavLink to="/supplies" className="btn btn-plenoil-primary">AVISO DE INVENTARIO</NavLink>
                    <span>
                        Previsto para {moment().format('YYYY-MM-DD') === moment(pending.next_planning).format('YYYY-MM-DD') ? 'hoy' : <>el {moment(pending.next_planning).format('DD-MM-YYYY')}</>}
                    </span>
                </StyledWrapper>
            }
        </React.Fragment>
	);
}


