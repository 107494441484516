import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Chat from 'services/Chat';

const StyledElement = styled.div`
	--heightWidth: 35px;

	position: absolute;
	top: calc(50% - (var(--heightWidth) / 2));
	right: calc((var(--heightWidth) / 2) * -1);
	background: var(--plenoil-red);
	border: 4px solid #09857b;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	height: var(--heightWidth);
	width: var(--heightWidth);
	font-size: calc(var(--heightWidth) * 0.4);
	line-height: calc(var(--heightWidth) * 0.4);
	border-radius: 50%;
`;

export default function ChatPendingMessagesIntervalChecker() {
	let [chatPendingMessages, setChatPendingMessages] = useState(0);

	// Check if pending chats
	useEffect(() => {
		const getData = async () => {
			let data = await Chat.getStations();
			
			let pending = data?.reduce((carry, item) => carry+=item.unread, 0);
			setChatPendingMessages(pending);
		};

		let getPendingChatsInterval = setInterval(() => {
			getData();
		}, 5000);
		getData();

		return function cleanup() {
			clearInterval(getPendingChatsInterval);
			Chat.cancel();
		}
	}, []);

	return (
		<>
			{chatPendingMessages > 0 && <StyledElement>{chatPendingMessages}</StyledElement>}
		</>
	);
}


