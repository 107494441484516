import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { assets } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { default as SuppliesService } from 'services/Supplies';
import { default as StationsService } from 'services/Stations';
import QtySelector from './QtySelector';
import moment from 'moment';

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	border: 1px solid white;
	color: white;
	background: var(--plenoil-orange);
	border-radius: 50%;
	font-size: 20px;
	margin-left: 10px;
	align-self: start;
	
`;

const Wrapper = styled.div`
	position: absolute;
	top: 15px;
	width: 100%;
	height: calc(100% - 15px);
	overflow: auto;
	padding-bottom: 20px;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;

	.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(255,255,255,0.3);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		user-select: none;
	}

	#title {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		padding: 0 30px;
		color: black;
		justify-content: center;
	}

	#search-box {
		margin-top: 20px;
		width: 100%;
	}

	#lines-wrapper { 
		padding: 20px 0;
		width: 95%;
		border: 1px solid lightgray;
		border-radius: 10px;
		margin-top: 10px;
		background: #fffaf6;

		.line {
			display: flex;
			color: black;
			background: #f1eeea;

			&:not(:last-of-type) {
				margin-bottom: 15px;	
			}

			div {
				width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				padding-left: 10px;

				small {
					position: absolute;
					top: 0px;
					right: 3px;
					font-size: 8px;
					opacity: 0.8;
				}
			}

			i {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 80px;
				background: #dcddd8;
				font-style: normal;
				cursor: pointer;
			}
		}

		.empty {
			text-align: center;
			color: var(--plenoil-red);
		}
	}

	button#btn-send {
		margin-top: 20px;
	}
`;

const ButtonsSelectorWrapper = styled.div`
	display: flex;
	margin-top: 40px;
`;

const SelectButton = styled.button` 
	border: 0;
	background: transparent;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: 80px;
		height: 80px;
		margin: 10px;
		margin-bottom: 10px;
	}

	span {
		display: block;
	}

	i {
		font-size: 10px;
		height: 20px;
		display: flex;
	}

	margin-bottom: 10px;
`;

const lineTemplate = {
	code: null,
	name: null,
	qty: 0
};

export default function SuppliesForm() {
	let navigate = useNavigate();

	let [station, setStation] = useState(null);
	let [lines, setLines] = useState([]);
	let [loading, setLoading] = useState(false);
	let [type, setType] = useState(null);
	let [qtySelectorVisible, setQtySelectorVisible] = useState(null);
	let [pendingToCreate, setPendingToCreate] = useState(null);

	useEffect(() => {
		return function cleanup() {
			SuppliesService.cancel();
		}
	}, []);

	useEffect(() => {
		if ( !type ) return;

		let getAvailableProducts = async () => {
			setLoading(true);

			let productsList = await SuppliesService.getProductsList(type, null);
			if ( !productsList ) productsList = [];

			setLines(productsList.map(el => {
				return {...lineTemplate, code: el.code, name: el.name, qty: null};
			}));

            setLoading(false);
		}
		getAvailableProducts();

	}, [type]);

	useEffect(() => {
		const getPendingToCreate = async () => {
			let data = await SuppliesService.getPendingPlanning();
			setPendingToCreate(data);
		}
		getPendingToCreate();
	}, []);

	useEffect(() => {
		const getStation = async () => {
			let station = await StationsService.get(getUser()?.stations[0] ?? null);
			setStation(station);
		};
		getStation();
	}, []);

	const setQty = (idx, qty) => {
		let newLines = [...lines];
		newLines[idx].qty = qty;
		setLines(newLines);
	}

	const sendForm = async () => {
		let c = window.confirm('¿Seguro que quieres enviar este pedido? Revisalo bien por que una vez enviado no podrás modificarlo.');
		if ( !c ) return;

		let linesToSend = lines.filter(el => el.qty !== null);

		setLoading(true);
		let result = await SuppliesService.addOrder(type, getUser()?.stations[0] ?? null, linesToSend);
		setLoading(false);

		if ( result.status === 1 ) {
			toast.success('Pedido creado');
			navigate('/supplies/orders-list');
		} else {
			toast.error('Error al crear el pedido');
		}
	}

	let btnSendEnabled = false;
	btnSendEnabled = lines && lines.length > 0 && lines.filter(el => el.qty === null).length === 0;
	
	return (
		<LoggedLayout hideFooter={true}>
			<Wrapper>
				<CloseButton onClick={() => navigate('/supplies')}>
					<i className="bi bi-chevron-left"></i>
				</CloseButton>

				{(!type && station) &&
					<ButtonsSelectorWrapper>
						{ console.log(getUser()) }
						<SelectButton onClick={() => setType('station')}>
							<img src={assets('/img/suministros.svg')} alt="estación" />
							<span>ESTACIÓN</span>
							<i>
								{pendingToCreate && pendingToCreate.type === 'station' && <>Previsto: {moment(pendingToCreate.next_planning).format('DD/MM/YYYY')}</>}

							</i>
						</SelectButton>
						{station.has_wash === 1 &&
							<SelectButton onClick={() => setType('wash')}>
								<img src={assets('/img/lavadero-iconos.svg')} alt="lavadero" />
								<span>LAVADERO</span>
								<i>
									{pendingToCreate && pendingToCreate.type === 'wash' && <>Previsto: {moment(pendingToCreate.next_planning).format('DD/MM/YYYY')}</>}
								</i>
							</SelectButton>
						}
					</ButtonsSelectorWrapper>
				}

				{type &&
					<>
						{ loading && <div className="loading"><img src={assets('/img/loader.gif')} alt="loading" /></div>}
						<div id="title">
							INVENTARIO
						</div>
						<div id="lines-wrapper">
							{lines?.map((el, idx) => {
								return (
									<div className="line" key={idx}>
										<div>
											{idx+1}.&nbsp;&nbsp;
											{el.name} 
											{/* <small>{el.code}</small> */}
										</div>
										<i onClick={(e) => setQtySelectorVisible(idx)}>{el.qty ?? '-'}</i>
									</div>
								);
							})}
							{lines?.length === 0 &&
								<div className="empty">No se han encontrado artículos</div>
							}
						</div>
						<button className="btn btn-plenoil-primary" id="btn-send" disabled={!btnSendEnabled} onClick={sendForm}>Enviar</button>	
					</>
				}
			</Wrapper>

			{qtySelectorVisible !== null &&
				<QtySelector 
					value={lines[qtySelectorVisible]?.qty ?? null}
					setValue={(qty) => setQty(qtySelectorVisible, qty)}
					close={() => setQtySelectorVisible(null)}
				/>
			}
		</LoggedLayout>
	);
}


