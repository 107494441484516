import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { default as SuppliesService } from 'services/Supplies';

const StyledElement = styled.div`
	--heightWidth: 20px;

	display: ${props => props.display === "true" ? 'flex' : 'none'};
	position: absolute;
	top: calc(50% - (var(--heightWidth) / 2));
	right: calc((var(--heightWidth) / 2) * -1);
	height: var(--heightWidth);
	width: var(--heightWidth);
	background: var(--plenoil-red);
	color: white;
	border-radius: 50%;
	font-size: calc(var(--heightWidth) * 0.6);
	align-items: center;
	justify-content: center;
`;

export default function BCOrdersToCheckPendingIntervalChecker() {
	let [orders, setOrders] = useState(null);

	useEffect(() => {
		const getData = async () => {
			let orders = await SuppliesService.getBCOrdersToCheckPending();
			if ( !orders ) return;
			setOrders(orders);
		};

		let getOrdersInterval = setInterval(() => {
			getData();
		}, 10000);
		getData();

		return function cleanup() {
			clearInterval(getOrdersInterval);
			SuppliesService.cancel();
		}
	}, []);

	return (
        <StyledElement className="parpadeo" display={orders?.length > 0 ? "true" : "false"}>
			<i className="bi bi-exclamation-triangle"></i>
        </StyledElement>
	);
}


